import { NextSeo } from 'next-seo';
import React, { FC } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import Head from './Head';
import { FooterWithNewsletter } from './chakraUIPro/FooterWithNewsletter';
import { Navbar } from './chakraUIPro/Navbar';

interface PageProps extends BoxProps {
  children: React.ReactNode;
  seoOptions?: any;
}

const Page: FC<PageProps> = ({ children, seoOptions, ...props }) => (
  <>
    <Head />
    <NextSeo title='Vitamin Club' description='' {...seoOptions} />

    <Navbar />

    <Box
      align='left'
      width='full'
      maxW='7xl'
      mx='auto'
      px={{ base: 2, md: 8 }}
      my={{ base: 2, md: 8 }}
      textAlign='left'
      {...props}
    >
      {children}
    </Box>

    <FooterWithNewsletter />
  </>
);

export default Page;
