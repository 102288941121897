import React from 'react';
import NextLink from 'next/link';
import { Button, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import Page from '../components/Page';

const Error = () => (
  <Page
    seoOptions={{
      title: 'Unfortunately, the page was not found | Vitamin Club',
      description: '',
      noindex: true,
      nofollow: true
    }}
  >
    <Stack as='main' spacing={8} justifyContent='center' alignItems='flex-start' m='0 auto 4rem auto' maxWidth='700px'>
      <Flex flexDirection='column' justifyContent='flex-start' alignItems='flex-start' maxW='6xl' mx='auto' my={12}>
        <Heading mb={2} as='h1' size='xl'>
          404 - We could not find this page.
        </Heading>
        <Text color='gray.500' my={4}>
          Rest assured, the techies have been notified. Please do us a favour and check the URL for any spelling errors
          in the meanwhile.
        </Text>
        <Button as={NextLink} href='/' p={[1, 4]} w='250px' m='3rem auto 0'>
          Return to the home page
        </Button>
      </Flex>
    </Stack>
  </Page>
);

export default Error;
